import { Link } from "gatsby";
import React from "react";
import { Alert, Button, Jumbotron } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { MdInfoOutline } from "react-icons/md";
import { buildImageObj } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
import BrandColorsHorizontalDivider from "./BrandColorsHorizontalDivider";
import NavSegment from "./NavSegment";
import NavItem from "./Network/NavItem";

export const getSpecificationDocument = (specificationDocumentWalkthroughs, type) =>
  specificationDocumentWalkthroughs.filter(
    specificationDocumentWalkthrough => specificationDocumentWalkthrough.type[0] === type
  )[0];

export const getBaseSpotlightRoute = network => `/network/${network.slug.current}/`;
export const getNavigationRoutes = (props, baseSpotlightRoute) => {
  return [
    {
      id: "be9d145e-326f-42f7-aa1c-c84e69620885",
      title: "Intro",
      route: `${baseSpotlightRoute}intro`,
      show: props.intro
    },
    {
      id: "b7bbfa83-bab2-481d-8fbb-d817b3135d05",
      title: "Guide",
      route: `${baseSpotlightRoute}deep-dive`,
      show: props.guide,
      subroutes: [
        {
          id: "8ec6558a-724a-4d6a-83e2-174e58e94155",
          title: "Implications",
          hash: `#implications`,
          show: true
        },
        {
          id: "d5271b25-7a4e-495d-9f8e-4b108c4566ae",
          title: "How it Works",
          hash: `#how-it-works`,
          show: true
        },
        {
          id: "f002d5d2-b916-4800-9a8c-912ccd91a024",
          title: "Tokens",
          hash: `#tokens`,
          show: true
        },
        {
          id: "ccf7b815-8c9a-49da-b079-e813de38cd54",
          title: "Network Roles",
          hash: `#network-roles`,
          show: true
        },
        {
          id: "e715c456-f76f-4776-ad7a-5d519b497ae0",
          title: "Technologies/Concepts",
          hash: `#technologies-concepts`,
          show: true
        }
      ]
    },
    {
      id: "65d23c20-8d48-437c-b0bf-cdd91a9dd93c",
      title: "Quiz",
      route: `${baseSpotlightRoute}quiz`,
      show: props.quiz
    },
    {
      id: "22b694e9-74c4-40f3-916a-e3fae7b824f4",
      title: "Details",
      route: `${baseSpotlightRoute}details`,
      show: props.details
    }
  ];
};

const SpotlightLayout2 = props => {
  const { children, resource } = props;

  const { spotlight, roles, systemComponents, specificationDocumentWalkthroughs } = resource;

  const { network, isCommissioned, _updatedAt, isInBeta } = spotlight;

  const baseSpotlightRoute = getBaseSpotlightRoute(network);

  const navigationRoutes = getNavigationRoutes(props, baseSpotlightRoute);

  return (
    <Container fluid className="mb-5">
      <Row className="mb-3">
        <Col xl={3} lg={{ span: 4 }}>
          <div className="mb-3 position-sticky" style={{ top: "1rem" }}>
            <div className="mb-1 d-flex flex-wrap align-center justify-content-between">
              <Link to={baseSpotlightRoute}>
                <img
                  src={imageUrlFor(buildImageObj(network._rawLogo))
                    .width(350)
                    .auto("format")
                    .url()}
                  className="img-fluid d-inline-block"
                  style={{ width: 175 }}
                  alt={`${network.title} cryptonetwork logo`}
                />
              </Link>
              <a href="https://1inch.exchange/#/r/0xE6E98e52Cd024Af8A41032fE7363DD7f4A8dFDBf/ETH/RUNE" target="_blank" rel="noreferrer noopener" className="d-lg-none">
                <Button style={{ background: 'linear-gradient(90deg, #00CCFF 0%, #33FF99 99.99%)', borderColor: '#005266', color: '#005266' }}>Buy $RUNE</Button>
              </a>
            </div>
            <div>
              <div>
                <div className="flex-column mb-3">
                  {navigationRoutes.length > 0 &&
                    navigationRoutes
                      .filter(navigationRoute => navigationRoute.show)
                      .map(navigationRoute => (
                        <NavItem
                          key={navigationRoute.id}
                          {...props}
                          navigationRoute={navigationRoute}
                        />
                      ))}
                </div>

                {network.title !== "tBTC" && (
                  <div
                    id="sidebar"
                    key="sidebar"
                    className="d-flex justify-content-between d-sm-block"
                  >
                    {roles.length > 0 && (
                      <>
                        <NavSegment title="Roles" items={roles} type="role" network={network} />
                        <hr className="my-2" />
                      </>
                    )}
                    <NavSegment
                      title="Components"
                      items={systemComponents.filter(
                        systemComponent => !systemComponent.externalLink
                      )}
                      type="systemComponent"
                      network={network}
                    />
                    <hr className="my-2" />
                    <NavSegment
                      title="Walkthroughs"
                      items={specificationDocumentWalkthroughs}
                      type="specificationDocumentWalkthrough"
                      network={network}
                    />
                  </div>
                )}
                <span className="d-none d-sm-inline-block">
                  <BrandColorsHorizontalDivider brandColors={network.brandColors} />
                  <div className="text-muted mb-3 d-none d-sm-block">Last updated {_updatedAt}</div>
                  <Button
                    href={`https://twitter.com/intent/tweet?text=https://rebase.foundation/network/${network.slug.current}`}
                    className="text-decoration-none mb-3"
                    variant="light"
                  >
                    Tweet this Spotlight
                  </Button>
                </span>
              </div>
            </div>
          </div>
        </Col>
        <Col xl={9} lg={{ span: 8 }}>
          {children}
          {isInBeta && (
            <div className="mb-3">
              <Alert variant="secondary">
                <div className="d-flex align-items-center">
                  <MdInfoOutline className="mr-1" size={30} />{" "}
                  <div>
                    This Spotlight is in beta. If you find something you think is wrong, help the
                    community and{" "}
                    <Alert.Link
                      className="text-decoration-none"
                      href="mailto:jay@rebase.foundation"
                    >
                      let us know
                    </Alert.Link>
                    .
                  </div>
                </div>
              </Alert>
            </div>
          )}
        </Col>
      </Row>
      {network.brandColors && (
        <BrandColorsHorizontalDivider brandColors={network.brandColors} marginBottomNumber={3} />
      )}
      <Jumbotron className="text-center">
        {isCommissioned && (
          <div className="mb-2 w-text mx-auto">
            This Spotlight was commissioned by the THORChain team to help spread knowledge and
            develop the community. Members of the Rebase team had holdings of RUNE exceeding $100 in
            value throughout the project.
          </div>
        )}
      </Jumbotron>
      {network.brandColors && (
        <BrandColorsHorizontalDivider brandColors={network.brandColors} marginBottomNumber={5} />
      )}
      <Jumbotron fluid className="text-center" style={{ backgroundColor: "#f1f1f1" }}>
        <h3 className="w-heading mx-auto">
          Interested in commissioning some crypto research or education?
        </h3>
        <a href="mailto:jay@rebase.foundation">Drop us an email</a>
      </Jumbotron>
    </Container>
  );
};

export default SpotlightLayout2;
