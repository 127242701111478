import { Link } from "gatsby";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";

const NavItem = props => {
  const active = props.path === props.navigationRoute.route;

  const [open, setOpen] = useState(active);
  const { brandColors } = props.network;

  return (
    <div
      className="py-2 pl-2"
      style={{
        borderLeft: `3px solid ${
          active ? (brandColors.length > 0 ? brandColors[0] : "black") : "#f1f1f1"
        }`
      }}
    >
      <Link className={`text-decoration-none`} to={props.navigationRoute.route}>
        <div className="d-flex align-items-center justify-content-between">
          <div className={`text-truncate ${active && "font-weight-bold"}`}>
            {props.navigationRoute.title}
          </div>
          {props.navigationRoute.subroutes && (
            <Button
              variant="light"
              size="sm"
              onClick={e => {
                e.preventDefault();
                setOpen(!open);
              }}
            >
              {open ? <FaCaretUp /> : <FaCaretDown />}
            </Button>
          )}
        </div>
        {open &&
          props.navigationRoute.subroutes &&
          props.navigationRoute.subroutes.map(subroute =>
            subroute.show ? (
              <Link
                to={`${props.navigationRoute.route}${subroute.hash}`}
                className={`d-block text-decoration-none my-1 pl-2 ${props.location.hash ===
                  subroute.hash && "font-weight-bold"}`}
                key={subroute.id}
              >
                {subroute.title}
              </Link>
            ) : null
          )}
      </Link>
    </div>
  );
};

export default NavItem;
