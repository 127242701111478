import { Link } from "gatsby";
import React, { useState } from "react";
import { MdAdd, MdArrowDropDown, MdRemove } from "react-icons/md";
import { getLink } from "../routes";

const NavSegment = ({ title = "", items = [], type = "", network = {} }) => {
  const [comingSoonOpen, setComingSoonOpen] = useState(false);
  const [showItems, setShowItems] = useState(false);

  const handleTrigger = () => setShowItems(!showItems);

  return (
    <div className="mb-2">
      <div className="mb-1">
        <b>
          {title}{" "}
          <MdArrowDropDown
            className="d-inline-block d-sm-none"
            onClick={handleTrigger}
            stlye={{ transition: "all .2s", filter: showItems && "rotate(180deg)" }}
          />
        </b>
      </div>
      <div className={`${showItems ? "d-block" : "d-none"} d-sm-block`}>
        {items
          .filter(item => item.isReady)
          .map(item => (
            <div key={item.id}>
              <Link
                to={getLink(network.slug.current, type, item.title)}
                className="d-flex align-items-center text-decoration-none mb-1"
              >
                {item.avatar && (
                  <img src={item.avatar.asset.fixed.src} className="mr-1" style={{ width: 30 }} />
                )}
                {item.title}
              </Link>
            </div>
          ))}
        {items.filter(item => !item.isReady).length > 0 && (
          <div
            className="mb-1 overline text-muted"
            style={{ cursor: "pointer" }}
            onClick={() => setComingSoonOpen(!comingSoonOpen)}
          >
            {comingSoonOpen ? <MdRemove className="mr-1" /> : <MdAdd className="mr-1" />}
            Coming soon
          </div>
        )}
        {comingSoonOpen &&
          items
            .filter(item => !item.isReady)
            .map(item => (
              <div
                key={item.id}
                className="d-flex align-items-center text-decoration-none mb-1 text-muted"
              >
                {item.avatar && (
                  <img
                    fixed={item.avatar.asset.fixed.src}
                    className="mr-1 img-grayscale"
                    style={{ width: 30 }}
                  />
                )}
                {item.title}
              </div>
            ))}
      </div>
    </div>
  );
};

export default NavSegment;
